import {Button, Checkbox, Dialog, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {ResponseCarriers} from "../../model/response/AirBookingFlightSearchResponse";
import {useEffect, useState} from "react";

export interface FilterDialogProps {
    open: boolean,
    onClose: (value: string[]) => void,
    responseCarriers: ResponseCarriers,

}

interface CarrierFilter {
    [key: string]: boolean;
}

export function FilterDialog(props: FilterDialogProps) {
    const {onClose, open, responseCarriers} = props;

    const [filters, setFilters] = useState<any>({});
    const [selectedValue, setSelectedValue] = useState<string[]>([]);

    useEffect(() => {
        // Set the default checked state based on the keys in airlineData
        const defaultFilters: CarrierFilter = {};
        Object.keys(responseCarriers).forEach((key) => {
            defaultFilters[key] = true; // Default is checked. Change this to false for default unchecked.
        });
        setFilters({...defaultFilters});
        setSelectedValue(Object.keys(responseCarriers));
    }, [responseCarriers]);

    const handleCheckboxChange = (event: { target: { name: any; checked: any; }; }) => {
        const {name, checked} = event.target;
        setFilters({...filters, [name]: checked});

        if (checked) {
            // Add filter
            setSelectedValue([...selectedValue, name]);
        } else {
            // Remove filter
            const updatedItems = selectedValue.filter((item: any) => item !== name);
            setSelectedValue(updatedItems);
        }
    };

    const handleClearFilters = () => {
        // Unchecks all
        const clearedFilters: CarrierFilter = {};
        Object.keys(responseCarriers).forEach((key) => {
            clearedFilters[key] = false;
        });
        setFilters(clearedFilters);
        setSelectedValue([]);
    };

    const handleSelectAll = () => {
        // Checks all
        const allFilters: CarrierFilter = {};
        Object.keys(responseCarriers).forEach((key) => {
            allFilters[key] = true;
        });
        setFilters(allFilters);
        setSelectedValue(Object.keys(filters))
    };

    const handleClose = () => {
        if (selectedValue.length > 0) {
            onClose(selectedValue);
        }
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                <FormLabel component="legend">Carrier</FormLabel>
                <Button onClick={handleClearFilters}>Deselect All</Button>
                <Button onClick={handleSelectAll}>Select All</Button>
                <FormGroup>
                    {Object.keys(responseCarriers).map(key => {
                        return (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox checked={filters[responseCarriers[key].airlineIataCode] || false}
                                              name={responseCarriers[key].airlineIataCode}
                                              onChange={handleCheckboxChange}/>
                                }
                                label={`${responseCarriers[key].airlineName} (${responseCarriers[key].airlineIataCode})`}
                            />
                        )
                    })}

                </FormGroup>
            </FormControl>
            <Button disabled={selectedValue.length === 0} color={"primary"} variant={"contained"} onClick={handleClose}
                    sx={{margin: "10px"}}>Apply</Button>
        </Dialog>
    );
}
