import {AccordionSummary, Box, Stack, Typography} from "@mui/material";
import {AirBookingLegType} from "../../utils/Enums";
import {FlightTicketItineraryLeg, LegDescriptionType} from "../../model/response/AirBookingFlightSearchResponse";
import {minutestoHoursAndMinutes} from "../../utils/DateFormatter";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from "@mui/icons-material/FlightLand";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    type: AirBookingLegType;
    itinerary: FlightTicketItineraryLeg;
    leg: LegDescriptionType;
    stopCount: number;
    getItineraryTime: Function;
}

export const LegSummary = (props: Props) => {

    function getStops() {
        if (props.stopCount < 1) {
            return 'Nonstop';
        } else if (props.stopCount === 1) {
            return '1 stop';
        } else {
            return `${props.stopCount} stops`;
        }
    }

    function getLegCarriers() {
        return props.itinerary.schedules
            .map(schedule => schedule.carrier.operatingCarrierName)
            .filter((value, index, array) => array.indexOf(value) === index);
    }

    return (
        <>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Stack spacing={2}>
                    {/* Carriers */}
                    <Stack direction={"row"} spacing={1}>
                        {getLegCarriers().map((carrier, index) =>
                            <Typography fontSize={"small"} fontWeight={"bold"} key={index}>
                                {carrier}
                            </Typography>)
                        }
                    </Stack>
                    {/* Carriers */}

                    {/* Trip summary */}
                    <Stack direction={"row"} spacing={{sm: 5, md: 6, lg: 10}} alignItems={"center"}>
                        {/* Take off */}
                        <Stack direction={"row"} spacing={3} alignItems={"center"}>
                            <FlightTakeoffIcon fontSize={"small"}/>
                            <Box>
                                <Typography variant={"body2"}>{props.getItineraryTime(props.itinerary.schedules[0].departure.time)}</Typography>
                                <Typography variant={"body2"}>{props.leg.departureLocation}</Typography>
                            </Box>
                        </Stack>
                        {/* Take off */}

                        {/* Duration */}
                        <Box padding={1.5}>
                            <Stack alignItems={"center"}>
                                <Typography variant={"caption"}
                                            fontStyle={"italic"}>{minutestoHoursAndMinutes(props.itinerary.totalLegElapsedTime).hours}h{minutestoHoursAndMinutes(props.itinerary.totalLegElapsedTime).minutes}m</Typography>
                                <hr className={"flight-search-hr"}/>
                                <Stack direction={"row"}>
                                    <Typography variant={"caption"}
                                                fontStyle={"italic"}>{getStops()}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        {/* Duration */}

                        {/* Landing */}
                        <Stack direction={"row"} spacing={3} alignItems={"center"}>
                            <FlightLandIcon fontSize={"small"}/>
                            <Box>
                                <Typography variant={"body2"}>{props.getItineraryTime(props.itinerary.schedules[props.stopCount].arrival.time)}</Typography>
                                <Typography variant={"body2"}>{props.leg.arrivalLocation}</Typography>
                            </Box>
                        </Stack>
                        {/* Landing */}

                    </Stack>
                    {/* Trip summary */}
                </Stack>
            </AccordionSummary>
        </>
    )
        ;
};
