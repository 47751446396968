import {Accordion} from "@mui/material";
import {FlightTicketItineraryLeg, LegDescriptionType} from "../../model/response/AirBookingFlightSearchResponse";
import {AirBookingLegType} from "../../utils/Enums";
import {LegSummary} from "./LegSummary";
import {LegDetails} from "./LegDetails";

interface Props {
    type: AirBookingLegType;
    itinerary: FlightTicketItineraryLeg;
    leg: LegDescriptionType;
    stopCount: number;
}

export const ItineraryLeg = (props: Props) => {

    function getItineraryTime(time: string) {
        // Example time: 11:45:00-04:00"
        return time.slice(0, 5);
    }

    function minutesToHours(minutes: number) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const layoverHours = hours > 0 ? `${hours}h` : "";
        return `${layoverHours}${remainingMinutes}m`
    }

    return (
        <Accordion elevation={0}>
            <LegSummary type={props.type} itinerary={props.itinerary} leg={props.leg} stopCount={props.stopCount}
                        getItineraryTime={getItineraryTime}/>

            {/*Schedule details*/}
            <LegDetails itinerary={props.itinerary} stopCount={props.stopCount}
                        getItineraryTime={getItineraryTime} minutesToHours={minutesToHours}/>
        </Accordion>
    );
};
