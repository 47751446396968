let AppConstants = {
    // env details
    envFile: process.env.REACT_APP_VARIABLE_NAME,

    // UI paths
    path: {
        home: "/home",
        searchFlight: "/search-flight",
        searchFlightResults: "/search-flight/results",
    },

    // Auth details
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD,

    // APIs
    endpoint: {
        getAirportsBy: process.env.REACT_APP_GET_AIRPORTS_BY || "",
        airBookingFlightSearch: process.env.REACT_APP_AIR_BOOKING_FLIGHT_SEARCH || ""
    }
}

export default AppConstants
