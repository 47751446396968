import {FlightTicketItinerary, LegDescriptionType} from "../../model/response/AirBookingFlightSearchResponse";
import {Box, Stack, Typography} from "@mui/material";
import {ItineraryLeg} from "./ItineraryLeg";
import {AirBookingLegType} from "../../utils/Enums";

interface Props {
    itinerary: FlightTicketItinerary;
    legs: LegDescriptionType[];
}

export const ItineraryItem = (props: Props) => {

    return (
        <Box>
            <Stack spacing={1}>
                {/*Price*/}
                <Typography fontWeight={"bold"} fontSize={"large"}>
                    ${Math.ceil(props.itinerary.totalFare.totalPrice)}
                </Typography>

                {/*Departing flight*/}
                <ItineraryLeg type={AirBookingLegType.DEPART} itinerary={props.itinerary.departLeg}
                              leg={props.legs[0]} stopCount={props.itinerary.departLeg.stopCount}/>

                {/*Returning flight*/}
                {props.legs.length === 2 &&
                    <ItineraryLeg type={AirBookingLegType.RETURN} itinerary={props.itinerary.returnLeg}
                                  leg={props.legs[1]} stopCount={props.itinerary.returnLeg.stopCount}/>}
            </Stack>
        </Box>
    );
};
