import React from 'react';
import './App.css';
import {Router} from "./Router";
import {BrowserRouter} from "react-router-dom";
import {NotificationProvider} from "./components/shared/context/NotificationContext";
import {LoadingProvider} from "./components/shared/context/LoadingContext";

function App() {
    console.log(process.env.NODE_ENV)
    return (
        <div className={"App"}>
            <LoadingProvider>
                <NotificationProvider>
                    <BrowserRouter>
                        <Router/>
                    </BrowserRouter>
                </NotificationProvider>
            </LoadingProvider>
        </div>
    );
}

export default App;
