import AppConstants from "./AppConstants";
import {AxiosRequestConfig} from "axios";

export const axiosConfig = (params?: any) => {
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const username = AppConstants.username || "";
    const password = AppConstants.password || "";
    const authHeaderValue = `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`;

    let config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": authHeaderValue,
            "Content-Type": "application/json",
        }
    }
    if (params) {
        config = {...config, params: params};
    }
    return config;
};
