import {
    FlightTicketItinerary,
    LegDescriptionType,
    ResponseCarriers
} from "../../model/response/AirBookingFlightSearchResponse";
import {ItineraryItem} from "./ItineraryItem";
import {Button, Pagination, Stack} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {FilterDialog} from "./FilterDialog";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface Props {
    itineraries: FlightTicketItinerary[];
    legs: LegDescriptionType[]
    carriers: ResponseCarriers;
}

export const ItineraryList = ({itineraries, legs, carriers}: Props) => {
        const [itemPerPage, setItemPerPage] = useState(5);
        const [page, setPage] = useState(1);
        const [totalPage, setTotalPage] = useState(Math.ceil(itineraries.length / itemPerPage));

        const [open, setOpen] = useState(false);
        const [selectedValue, setSelectedValue] = useState<string[]>(Object.keys(carriers));

        const [filteredItinerary, setFilteredItinerary] = useState<FlightTicketItinerary[]>([]);

        const itineraryListRef = useRef<any>(null)

        const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value);
            if (itineraryListRef.current) {
                const targetElement = itineraryListRef.current;
                const yOffset = targetElement.getBoundingClientRect().top;
                window.scrollTo({top: yOffset, behavior: "auto"});
            }
        };

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = (selected: string[]) => {
            setOpen(false);
            setSelectedValue(selected);
        };

        useEffect(() => {
            const filtered = itineraries.filter(itinerary => {
                // Check the departure leg schedules
                const departureSchedules = itinerary.departLeg.schedules;
                for (const schedule of departureSchedules) {
                    if (isCarrierInFilter(schedule.carrier.marketing) || isCarrierInFilter(schedule.carrier.operating)) {
                        return true; // Found a matching carrier, include this itinerary
                    }
                }
                // Check the return leg schedules
                const returnSchedules = itinerary.returnLeg.schedules;
                if (returnSchedules) {
                    for (const schedule of returnSchedules) {
                        if (isCarrierInFilter(schedule.carrier.marketing) || isCarrierInFilter(schedule.carrier.operating)) {
                            return true; // Found a matching carrier, include this itinerary
                        }
                    }
                }
                return false; // No matching carrier found in either departure or return legs

            });
            setPage(1);
            setFilteredItinerary([...filtered]);
        }, [selectedValue]);

        const isCarrierInFilter = (carrierCode: string) => {
            return selectedValue.includes(carrierCode);
        };


        useEffect(() => {
            if (selectedValue.length === Object.keys(carriers).length) {
                setTotalPage(Math.ceil(itineraries.length / itemPerPage))
            } else {
                setTotalPage(Math.ceil(filteredItinerary.length / itemPerPage))
            }
        }, [filteredItinerary]);

    useEffect(() => {
        setFilteredItinerary(itineraries);
        setPage(1);
    }, [itineraries]);

    return (
            <Stack spacing={1}>
                <Stack direction={{sm: "column", md: "row", lg: "row"}} spacing={2} justifyContent={"space-between"}>
                    <div ref={itineraryListRef}/>
                    <Button onClick={handleClickOpen} variant={"contained"} size={"small"}>
                        <FilterAltIcon fontSize={"small"}/>
                        Filter
                    </Button>
                    <FilterDialog
                        open={open}
                        onClose={handleClose}
                        responseCarriers={carriers}
                    />
                </Stack>

                {filteredItinerary.slice((page-1) * itemPerPage, (page-1) * itemPerPage + itemPerPage).map(itinerary => {
                    return (
                        <ItineraryItem key={itinerary.id} itinerary={itinerary} legs={legs}/>
                    )
                })}

                <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                    color={"primary"}
                />
            </Stack>
        );
    }
;
