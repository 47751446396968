import {format} from "date-fns";

export const isoFormatter = (date: string | Date) => {
    return formatDate(date, "yyyy-MM-dd'T00:00:00'");
}

export const formatDate = (date: string | Date, formatter: string) => {
    if (typeof date === "string") {
        return format(new Date(date), formatter);
    }
    return format(date, formatter)
}

export interface HoursAndMinutes {
    hours: number;
    minutes: number;
}

export const minutestoHoursAndMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedDuration: HoursAndMinutes = {
        hours: hours,
        minutes: remainingMinutes
    }
    return formattedDuration;
}
