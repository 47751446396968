import axios from "axios";
import {axiosConfig} from "./AxiosConfig";

interface Response {
    data: any;
}

export const httpGet = async (url: string, params?: any) => {
    try {
        return await axios.get(url, axiosConfig(params));
    } catch (error: any) {
        return error;
    }
}

export const httpPost = async (url: string, requestBody: any): Promise<Response> => {
    try {
        return await axios.post(url, requestBody, axiosConfig());
    } catch (error: any) {
        if (error.response) {
            return error.response.data;
        }
        return error;
    }
}


