import {Route, Routes} from "react-router-dom";
import {FlightSearchScreen} from "./screens/FlightSearchScreen";
import {PageNotFound} from "./screens/PageNotFound";
import AppConstants from "./utils/AppConstants";
import {FlightSearchResultsScreen} from "./screens/FlightSearchResultsScreen";

export function Router() {

    return (
        <Routes>
            <Route path="/" element={<FlightSearchScreen/>}></Route>
            <Route path={AppConstants.path.home} element={<FlightSearchScreen/>}></Route>
            <Route path={AppConstants.path.searchFlight} element={<FlightSearchScreen/>}></Route>
            <Route path={AppConstants.path.searchFlightResults} element={<FlightSearchResultsScreen/>}/>
            <Route path="*" element={<PageNotFound/>}></Route>

        </Routes>
    );
}
