import {createContext, useContext, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";

interface LoadingContextValue {
    loading: boolean,
    handleLoading: Function,
    handleSetBackdrop: Function,
}

const LoadingContext = createContext<LoadingContextValue | undefined>(undefined);

export function useLoading() {
    return useContext(LoadingContext);
}

/**
 * @param loadingContext
 * @param loading loading status
 * @param backdrop can be set to true to display fullscreen backdrop
 */
export function setLoading(loadingContext: LoadingContextValue | undefined, loading: boolean, backdrop?: boolean): void {
    loadingContext?.handleLoading(loading);
    if (backdrop) {
        loadingContext?.handleSetBackdrop(backdrop);
    }
    if (!loading) {
        loadingContext?.handleSetBackdrop(false);
    }
}

// @ts-ignore
export const LoadingProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [backdrop, setBackdrop] = useState(false);

    function handleLoading(status: boolean) {
        setLoading(status);
    }

    function handleSetBackdrop(open: boolean) {
        setBackdrop(open);
    }

    const value: LoadingContextValue = {
        loading,
        handleLoading,
        handleSetBackdrop
    }
    return (
        <LoadingContext.Provider value={value}>
            {children}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={backdrop}
                onClick={() => handleSetBackdrop(false)}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </LoadingContext.Provider>
    );
};
