import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {AirportSmartSearch} from "./AirportSmartSearch";
import {FlightDateInput} from "./FlightDateInput";
import {Airport} from "../../../model/common/Airport";
import {SelectedContainer} from "./SelectedContainer";
import {airBookingFlightSearch} from "../../../service/airbooking/AirBookingService";
import {CabinType, cabinTypes, SearchCriteria} from "../../../model/request/SearchCriteria";
import {isoFormatter} from "../../../utils/DateFormatter";
import {setLoading, useLoading} from "../../shared/context/LoadingContext";
import {showNotification, useNotification} from "../../shared/context/NotificationContext";
import SyncIcon from '@mui/icons-material/Sync';

interface Props {
    handleFlightSearch: Function
}

type TripType = 'one-way' | 'round-trip';

export function FlightSearchForm(props: Props) {
    const [tripType, setTripType] = useState<TripType>("round-trip");
    const [fromAirport, setFromAirport] = useState<Airport | null>(null);
    const [toAirport, setToAirport] = useState<Airport | null>(null);
    const [departTimestamp, setDepartTimestamp] = useState<string | null>(null);
    const [returnTimestamp, setReturnTimestamp] = useState<string | null>(null);
    const [cabinType, setCabinType] = useState<CabinType>("Y");
    const [validForm, setValidForm] = useState(false);

    const loadingContext = useLoading();
    const notificationContext = useNotification();

    // TODO: Refactor duplicate components
    function handleTripType(event: React.MouseEvent<HTMLElement>, newTripType: TripType) {
        setTripType(newTripType);
        if (newTripType === "one-way") {
            setReturnTimestamp(null);
        }
    }

    function isRoundTrip(tripType: string) {
        return tripType === "round-trip";
    }

    function handleSetFromAirport(value: Airport) {
        setFromAirport(value)
    }

    function handleSetToAirport(value: Airport) {
        setToAirport(value);
    }

    function handleSetDepartTimestamp(date: string) {
        setDepartTimestamp(isoFormatter(date));
    }

    function handleSetReturnTimestamp(date: string) {
        setReturnTimestamp(date);
    }

    function handleClearSelectedFromAirport() {
        setFromAirport(null);
        setToAirport(null);
    }

    function handleClearSelectedToAirport() {
        setToAirport(null);
    }

    function handleCabinTypeChange(event: SelectChangeEvent) {
        setCabinType(event.target.value as CabinType);
    }

    const searchCriteria = () => {
        let searchCriteria = new SearchCriteria();
        searchCriteria.fromAirportCode = fromAirport?.iataCode;
        searchCriteria.toAirportCode = toAirport?.iataCode;
        searchCriteria.departTimestamp = departTimestamp;
        searchCriteria.returnTimestamp = returnTimestamp;
        searchCriteria.cabinType = cabinType;

        return searchCriteria;
    }

    async function search(event: any) {
        event.preventDefault();
        setLoading(loadingContext, true, true);
        let response = await airBookingFlightSearch(searchCriteria());
        if (response.data) {
            props.handleFlightSearch(response.data);
        } else {
            showNotification(notificationContext, response.message ?? `${response.detail} (${response.status})`, "error", 10000);
        }
        setLoading(loadingContext, false);
    }

    function switchDestinations() {
        const temp = fromAirport;
        setFromAirport(toAirport);
        setToAirport(temp);
    }

    function validateForm() {
        setValidForm(meetLocationRequirement() && meetTripTypeRequirement());
    }

    useEffect(() => {
        validateForm();
    }, [tripType, fromAirport, toAirport, departTimestamp, returnTimestamp]);

    useEffect(() => {
    }, [validForm]);

    const meetTripTypeRequirement = () => {
        if (isRoundTrip(tripType)) {
            if (departTimestamp && returnTimestamp) {
                const d = new Date(departTimestamp);
                const r = new Date(returnTimestamp);
                console.log(d, r)
                return  d <= r;
            }
            return false;
        } else {
            return  departTimestamp !== null;
        }
    }

    const meetLocationRequirement = () => {
        if (fromAirport && toAirport) {
            return fromAirport.iataCode !== toAirport.iataCode
        }
        else return false;
    }


    return (
        <form onSubmit={search}>
            <Stack spacing={2}>
                {/* Trip type button group */}
                <div className={"flight-type-toggle-button-group"}>
                    <ToggleButtonGroup
                        value={tripType}
                        exclusive
                        onChange={handleTripType}
                        aria-label="trip-type"
                        color="primary"

                    >
                        <ToggleButton value="one-way" aria-label="one-way" disabled={tripType === "one-way"}>
                            One Way
                        </ToggleButton>
                        <ToggleButton value="round-trip" aria-label="round-trip" disabled={tripType === "round-trip"}>
                            Round Trip
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                {/* Trip type button group */}

                {/* Destinations */}
                <Stack direction={"row"}>
                    <Stack direction={{sm: "column", md: "row", lg: "row"}} spacing={{sm: 0, md: 2, lg: 2}}>
                        {/* From airport */}
                        <div>
                            <InputLabel>From Airport</InputLabel>
                            {!fromAirport &&
                                <AirportSmartSearch handleSetAirport={handleSetFromAirport} airport={fromAirport}/>
                            }
                            {fromAirport &&
                                <Stack direction={"row"}>
                                    <SelectedContainer airport={fromAirport}
                                                       handleClearSelectedAirport={handleClearSelectedFromAirport}/>
                                </Stack>
                            }
                        </div>
                        {/* From airport */}

                        {/* To airport */}
                        <div>
                            <InputLabel>To Airport</InputLabel>
                            <AirportSmartSearch handleSetAirport={handleSetToAirport} airport={toAirport}
                                                disable={!fromAirport}/>
                            {toAirport &&
                                <Stack direction={"row"}>
                                    <SelectedContainer airport={toAirport}
                                                       handleClearSelectedAirport={handleClearSelectedToAirport}/>
                                </Stack>
                            }
                        </div>
                        {/* To airport */}

                        {/* Switch button */}
                        {fromAirport && toAirport &&
                            <IconButton onClick={switchDestinations} size={"small"}>
                                <SyncIcon/>
                            </IconButton>}
                        {/* Switch button */}
                    </Stack>


                </Stack>
                {/* Destinations */}

                {/* Date time */}
                <Stack direction={{sm: "column", md: "row", lg: "row"}} spacing={2}>
                    {/* Depart date */}
                    <div>
                        <InputLabel>Depart Date</InputLabel>
                        <FlightDateInput date={departTimestamp} handleSetTimestamp={handleSetDepartTimestamp}/>
                        {/*{departTimestamp &&*/}
                        {/*    <Stack direction={"row"}>*/}
                        {/*        <SelectedContainer date={departTimestamp}/>*/}
                        {/*        <IconButton edge="end" onClick={handleClearSelectedDepartTimestamp}>*/}
                        {/*            <HighlightOffIcon/>*/}
                        {/*        </IconButton>*/}
                        {/*    </Stack>*/}
                        {/*}*/}
                    </div>
                    {/* Depart date */}

                    {/* Return date */}
                    {isRoundTrip(tripType) &&
                        <>
                            <div>
                                <InputLabel>Return Date</InputLabel>
                                <FlightDateInput date={returnTimestamp} minDate={departTimestamp ?? ""} handleSetTimestamp={handleSetReturnTimestamp}/>
                                {/*{returnTimestamp &&*/}
                                {/*    <Stack direction={"row"}>*/}
                                {/*        <SelectedContainer date={returnTimestamp}/>*/}
                                {/*        <IconButton edge="end" onClick={handleClearSelectedReturnTimestamp}>*/}
                                {/*            <HighlightOffIcon/>*/}
                                {/*        </IconButton>*/}
                                {/*    </Stack>*/}
                                {/*}*/}
                            </div>
                        </>
                    }
                    {/* Return date */}
                </Stack>
                {/* Date time */}

                {/* Cabin Type Selection */}
                <div>
                    <InputLabel id="cabin-type-input-label">Cabin Type</InputLabel>
                    <Select
                        labelId="cabin-type-select-label"
                        id="cabin-type-select"
                        value={cabinType}
                        onChange={handleCabinTypeChange}
                        fullWidth
                    >
                        {Object.keys(cabinTypes).map((key: string) => {
                            return (
                                <MenuItem key={key} value={key}>{cabinTypes[key]}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
                {/* Cabin Type Selection */}

                <Button disabled={!validForm} type="submit" variant="contained" color="primary">Search</Button>

            </Stack>
        </form>
    );
}
