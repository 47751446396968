import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export interface NavigateButtonProps {
    path: string,
    variant?: "text" | "outlined" | "contained" | undefined
    text: string
}

export function NavigateButton(props: NavigateButtonProps) {
    const path = props.path;
    const variant = props.variant || "contained";
    const text = props.text;
    const navigate = useNavigate();

    return (
        <Button variant={variant} onClick={() => navigate(path)}>
            {text}
        </Button>
    );
}
