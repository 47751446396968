import {createContext, useContext, useState} from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

/**
 * Provider for notification snack bar.
 */
interface NotificationContextValue {
    handleSetOpen: Function,
    handleSetMessage: Function,
    handleSetType: Function,
    handleDuration: Function
}

const NotificationContext = createContext<NotificationContextValue | undefined>(undefined);

export function useNotification() {
    return useContext(NotificationContext);
}

export function showNotification(notificationContext: NotificationContextValue | undefined, message: string, type: AlertColor, duration?: number) {
    notificationContext?.handleSetMessage(message);
    notificationContext?.handleSetType(type);
    notificationContext?.handleSetOpen(true);
    notificationContext?.handleDuration(duration);
}

// @ts-ignore
export const NotificationProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState<AlertColor>("info");
    const [duration, setDuration] = useState<number>(99999999);

    function handleSetOpen(open: boolean) {
        setOpen(open);
    }
    function handleSetMessage(message: string) {
        setMessage(message);
    }

    function handleSetType(type: AlertColor) {
        setType(type);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleDuration(duration: number) {
        setDuration(duration);
    }

    const value: NotificationContextValue = {
        handleSetOpen,
        handleSetMessage,
        handleSetType,
        handleDuration
    }

    return (
        <NotificationContext.Provider value={value}>
            <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </NotificationContext.Provider>
    );
};
