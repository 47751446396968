import {Box, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {Airport} from "../../../model/common/Airport";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface Props {
    airport: Airport;
    handleClearSelectedAirport: any;
}

export const SelectedContainer = (props: Props) => {
    return (
        <>
            <Box sx={{
                padding: "10px",
                borderRadius: "5px",
                border: "2px solid #BAD7E9",
                width: "fit-content",
                minWidth: "350px"
            }}>
                <Stack direction={"row"} spacing={"10px"} justifyContent={"space-between"}>
                    <Stack direction={"column"}>
                        {props.airport &&
                            (<>
                                <Typography
                                    variant={"body1"}>{props.airport.iataCode} - {props.airport.city}, {props.airport.country}
                                </Typography>
                                <Typography variant={"caption"}
                                            fontStyle={"italic"}>{props.airport.name}
                                </Typography>
                            </>)
                        }
                    </Stack>
                    <IconButton size={"small"} edge="end" onClick={props.handleClearSelectedAirport}>
                        <HighlightOffIcon/>
                    </IconButton>
                </Stack>
            </Box>

        </>
    );
};
