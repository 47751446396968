import {createTheme} from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {Airport} from "../../../model/common/Airport";
import {getAirportsBy} from "../../../service/airbooking/AirportService";
import {setLoading, useLoading} from "../../shared/context/LoadingContext";
import {showNotification, useNotification} from "../../shared/context/NotificationContext";


interface Props {
    handleSetAirport: Function,
    airport: Airport | null,
    disable?: boolean
}

export const AirportSmartSearch = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const loadingContext = useLoading();
    const notificationContext = useNotification();

    const [airportList, setAirportList] = useState<Airport[]>([]);

    async function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value;
        if (value.length >= 2) {
            setAnchorEl(event.currentTarget);
            await getAndSetAirportList(value);
        } else {
            setAnchorEl(null);
        }
    }

    async function getAndSetAirportList(value: string) {
        setLoading(loadingContext, true);
        let response = await getAirportsBy(value);
        if (response.data) {
            let airportListResponse = response.data;
            setAirportList(airportListResponse);
        } else {
            showNotification(notificationContext, response, "error", 10000);
        }
        setLoading(loadingContext, false);
    }

    function handleMenuItemClick(airport: Airport) {
        props.handleSetAirport(airport);
        setAnchorEl(null);
    }

    return (
        <ThemeProvider theme={theme}>
            {!props.airport &&
                <TextField
                    id={"from-airport"}
                    required={true}
                    disabled={props.disable || false}
                    onChange={handleInputChange}
                    autoComplete={"off"}
                    placeholder={"Search by airport code or city"}
                    sx={{width: "300px", height: "fit-content"}}
                />}
            <Popper open={open} anchorEl={anchorEl}>
                <Paper sx={{
                    maxHeight: "fit-content",
                    minWidth: "300px",
                    borderRadius: "5px",
                    boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px"
                }}>
                    <MenuList>
                        {loadingContext?.loading &&
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CircularProgress size={30}/>
                            </Box>
                        }
                        {!loadingContext?.loading && airportList.length > 0 &&
                            airportList.map((airport, index) => {
                                return (
                                    <MenuItem key={index} onClick={() => handleMenuItemClick(airport)}>
                                        <Stack direction={"column"}>
                                            <Typography
                                                variant={"body1"}>{airport.iataCode} - {airport.city}, {airport.country}</Typography>
                                            <Typography variant={"caption"}
                                                        fontStyle={"italic"}>{airport.name}</Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })
                        }
                        {!loadingContext?.loading && airportList.length === 0 &&
                            <Typography variant={"caption"} fontStyle={"italic"} padding={5}>
                                No airport found. Please try a different code or city.
                            </Typography>
                        }
                    </MenuList>
                </Paper>
            </Popper>
        </ThemeProvider>
    )
        ;
};

export const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    }
});
