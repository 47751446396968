import {Typography} from "@mui/material";
import {NavigateButton} from "../components/shared/navigation/NavigateButton";

export const FlightSearchResultsScreen = () => {
    return (
        <>
            <Typography>Hello</Typography>
            <NavigateButton path={"/search-flight"} text={"SearchFlight"}/>
        </>
    );
};
