import React, {useState} from "react";
import {DatePicker, DateValidationError, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {isoFormatter} from "../../../utils/DateFormatter";
import {isAfter, isToday} from "date-fns";

interface Props {
    handleSetTimestamp: Function,
    date: string | null
    minDate?: string;
}

export const FlightDateInput = (props: Props) => {
    const [error, setError] = useState<DateValidationError | null>(null);

    function handleSetTimestamp(date: any) {
        if (validateDate(date)) {
            props.handleSetTimestamp(isoFormatter(date));
        }
    }

    function validateDate(date: any) {
        return isAfter(date, new Date()) || isToday(date);
    }

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'maxDate':
            case 'minDate': {
                return 'Please select a valid date';
            }

            case 'invalidDate': {
                return 'Invalid date value.';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    onChange={handleSetTimestamp}
                    sx={{minWidth: "300px"}}
                    disablePast={true}
                    minDate={props.minDate ? new Date(props.minDate) : new Date()}
                    onError={(newError) => setError(newError)}
                    slotProps={{
                        textField: {
                            required: true,
                            helperText: errorMessage,
                        },
                    }}
                />
            </LocalizationProvider>

        </>
    );
};
