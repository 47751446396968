import {AccordionDetails, Stack, Typography} from "@mui/material";
import {FlightTicketItineraryLeg} from "../../model/response/AirBookingFlightSearchResponse";

interface Props {
    itinerary: FlightTicketItineraryLeg;
    stopCount: number;
    getItineraryTime: Function;
    minutesToHours: Function;
}

export const LegDetails = (props: Props) => {
    return (
        <>
            {props.itinerary.schedules.map((schedule, index) => {
                return (
                    <AccordionDetails key={index}>
                        <Stack spacing={2}>

                            {/* Carrier info */}
                            <Stack direction={"row"} spacing={1}>
                                <Typography fontStyle={"italic"} fontSize={"small"} fontWeight={"bold"}>
                                    {schedule.carrier.marketingCarrierName} {schedule.carrier.marketingFlightNumber}
                                </Typography>
                                <Typography fontStyle={"italic"} fontSize={"small"}>
                                    {schedule.carrier.marketing !== schedule.carrier.operating ? `Operated by ${schedule.carrier.operatingCarrierName}` : ""}
                                </Typography>
                            </Stack>
                            {/* Ends Carrier info */}

                            {/* Trip info */}
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <div>
                                    <div className={"vertical-line"}></div>
                                </div>

                                <Stack spacing={1}>
                                    <Stack direction={"row"} spacing={3}>
                                        <Typography fontWeight={"bold"}>
                                            {props.getItineraryTime(schedule.departure.time)}
                                        </Typography>
                                        <Typography>
                                            {schedule.departure.airportName} ({schedule.departure.airport})
                                        </Typography>
                                    </Stack>

                                    <Stack direction={"row"} spacing={3}>
                                        <Typography fontWeight={"bold"}>
                                            {props.getItineraryTime(schedule.arrival.time)}
                                        </Typography>
                                        <Typography>
                                            {schedule.arrival.airportName} ({schedule.arrival.airport})
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            {/* Ends Trip info */}


                            {/* Layover info */}
                            {index > -1 && index < props.stopCount && (
                                <Typography fontSize={"small"} fontStyle={"italic"}>
                                    Layover: {props.itinerary.layoverAirportName[index]} ({props.itinerary.layoverAirport[index]})
                                    - {props.minutesToHours(props.itinerary.layoverTime[index])}
                                </Typography>
                            )}
                            {/* Ends Layover info */}
                        </Stack>
                    </AccordionDetails>
                )
            })}
        </>
    );
}
