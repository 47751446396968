import {FlightSearchForm} from "../form/flight-search/FlightSearchForm";
import React, {useState} from "react";
import {Box, Stack} from "@mui/material";
import {
    AirBookingFlightSearchResponse,
    FlightTicketItinerary,
    LegDescriptionType,
    ResponseCarriers
} from "../../model/response/AirBookingFlightSearchResponse";
import {ItineraryList} from "./ItineraryList";

export function FlightSearch() {
    const [itineraries, setItineraries] = useState<FlightTicketItinerary[]>([]);
    const [legs, setLegs] = useState<LegDescriptionType[]>([]);
    const [carriers, setCarriers] = useState<ResponseCarriers>({});
    const [airBookingFlightSearchResponse, setAirBookingFlightSearchResponse] = useState<AirBookingFlightSearchResponse>();

    function handleFlightSearch(response: AirBookingFlightSearchResponse) {
        if (response && response.itineraryCount > 0) {
            console.log(response);
            setAirBookingFlightSearchResponse(response);
            setItineraries(response.flightTicketItineraries);
            setLegs(response.legs);
            setCarriers(response.responseCarriers);
        }
    }

    return (
        <Stack direction={"column"} spacing={2} alignItems={"center"}>
            <Box className={"flight-search-container"}>
                <FlightSearchForm handleFlightSearch={handleFlightSearch}/>
            </Box>

            {airBookingFlightSearchResponse && (
                <Box className={"flight-search-result-container"}>
                    <ItineraryList itineraries={itineraries} legs={legs} carriers={carriers}/>
                </Box>)}

        </Stack>
    );
}
