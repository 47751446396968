import AppConstants from "../../utils/AppConstants";
import {httpGet} from "../../utils/HttpRequest";

export async function getAirportsBy(criteria: string) {
    const url = AppConstants.endpoint.getAirportsBy;
    const params = {
        criteria: criteria
    }
    return await httpGet(url, params);
}
