export class SearchCriteria {
    public fromAirportCode: string | undefined = "";
    public toAirportCode: string | undefined = "";
    public departTimestamp: string | null = "";
    public returnTimestamp: string | null = null;
    public cabinType: CabinType = "Y";
}
export type CabinType = "PremiumFirst" | "First" | "PremiumBusiness" | "Business" | "PremiumEconomy" | "Economy" | "Y" | "S" | "C" | "J" | "F" | "P";

export const cabinTypes: {[key:string]: string} = {
    P: "Premium First",
    F: "First",
    J: "Premium Business",
    C: "Business",
    S: "Premium Economy",
    Y: "Economy",
}
